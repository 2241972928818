@import 'application';

/* Mastodon der FeM nutzen */
.drawer__inner__mastodon {
  background: url('../images/mastodon-fem.svg') no-repeat 0 100%/contain;
}

/* Lasse das ursprüngliche Mastodon Logo verschwinden */
.drawer__inner__mastodon img, .landing-page__mascot img {
  display: none;
}

/* Mache Links in Posts blau */
.status__content a {
  color: #49a0de;
}

